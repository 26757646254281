import { V as VTTParser, a as VTTBlock, b as VTTCue } from './index.js';

const TIMESTAMP_SEP = ",";
class SBVParser extends VTTParser {
  parse(line, lineCount) {
    if (line === "") {
      if (this.a) {
        this.j.push(this.a);
        this.f.onCue?.(this.a);
        this.a = null;
      }
      this.c = VTTBlock.None;
    } else if (this.c === VTTBlock.Cue) {
      this.a.text += (this.a.text ? "\n" : "") + line;
    } else if (line.includes(TIMESTAMP_SEP)) {
      const result = this.o(line, lineCount);
      if (result) {
        this.a = new VTTCue(result[0], result[1], "");
        this.c = VTTBlock.Cue;
      }
    }
    this.l = line;
  }
  o(line, lineCount) {
    const parts = line.split(TIMESTAMP_SEP);
    if (parts.length !== 2)
      return void 0;
    const start = this.N(parts[0].trim());
    const end = this.N(parts[1].trim());
    if (start === null || end === null) {
      this.e(this.d?.w("timestamp", line, lineCount));
      return void 0;
    }
    return [start, end, []];
  }
  N(part) {
    const match = /^(\d{1,2}):(\d{2}):(\d{2})\.(\d{3})$/.exec(part);
    if (!match)
      return null;
    const [, hours, minutes, seconds, milliseconds] = match;
    return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10) + parseInt(milliseconds, 10) / 1e3;
  }
}
function createSBVParser() {
  return new SBVParser();
}

export { SBVParser, createSBVParser as default };
